import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Obfuscate from 'react-obfuscate';

const philosophyPage = () => {
    return (
      <Layout>
         <Seo
        title="Legal Disclosure"/>
        <div className="h-20"></div>
        <div className="w-4/5 lg:w-1/2 m-auto py-32 space-y-10">

        <h1 className="text-4xl lg:text-6xl font-semibold my-10">Impressum - Legal Disclosure</h1>

<p><strong>According to § 5 TMG</strong><br />
Heinrig Filmtechnik Gilstein &amp; Heinrich GbR<br />
Albert-Schweitzer-Straße 12<br />
64807 Dieburg</p>

<p><strong>Represented by:</strong><br />
Georg Gilstein, Philipp Heinrich</p>

<p><strong>Contact:</strong><br />
Phone: <Obfuscate tel="+496071-4306836" /><br />
Mail: <Obfuscate
      email="hallo@heinrig.tech"
      headers={{
        subject: 'Contact via website',
      }}/></p>

<p><strong>VAT ID / UStId:</strong> <br />
Umsatzsteuer-Identifikationsnummer according to § 27 A sales tax law:<br />
DE344569989</p>

<p><strong>EU dispute settlement</strong><br />
The European Commission provides a platform for online dispute resolution (OS): <a className="inlinelink" href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.
You can find our email address in the legal notice above.</p>

<p><strong>Consumer dispute settlement / universal arbitration board</strong><br />
We take part in a dispute settlement procedure before a consumer arbitration board. The universal arbitration board of the Center for Arbitration e.V., Straßburger Straße 8, 77694 Kehl am Rhein (<a href="https://www.verbrauch-schlichter.de" className="inlinelink">
  
  https://www.verbrauch-schlichter.de
</a>).</p>


   
<h1 className="text-4xl lg:text-6xl font-semibold my-10">Privacy Policy</h1>

<p>At Heinrig Filmtechnik GbR, accessible from heinrig.tech, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Heinrig Filmtechnik and how we use it.</p>

<p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us. Our Privacy Policy was generated with the help of <a href="https://www.gdprprivacynotice.com/">GDPR Privacy Policy Generator from GDPRPrivacyNotice.com</a></p>

<h2 className="font-semibold text-xl">General Data Protection Regulation (GDPR)</h2>
<p>We are a Data Controller of your information.</p>

<p>Heinrig Filmtechnik GbR Gilstein &amp; Heinrich GbR legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
<ul>
    <li>Heinrig Filmtechnik GbR Gilstein &amp; Heinrich GbR needs to perform a contract with you</li>
    <li>You have given Heinrig Filmtechnik GbR Gilstein &amp; Heinrich GbR permission to do so</li>
    <li>Processing your personal information is in Heinrig Filmtechnik GbR Gilstein &amp; Heinrich GbR legitimate interests</li>
    <li>Heinrig Filmtechnik GbR Gilstein &amp; Heinrich GbR needs to comply with the law</li>
</ul>
  
<p>Heinrig Filmtechnik GbR Gilstein &amp; Heinrich GbR will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>

<p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.</p>
<p>In certain circumstances, you have the following data protection rights:</p>
<ul>
    <li>The right to access, update or to delete the information we have on you.</li>
    <li>The right of rectification.</li> 
    <li>The right to object.</li>
    <li>The right of restriction.</li>
    <li>The right to data portability</li>
    <li>The right to withdraw consent</li>
</ul>

<h2 className="font-semibold text-xl">Log Files</h2>

<p>Heinrig Filmtechnik follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

<h2 className="font-semibold text-xl">Cookies and Web Beacons</h2>

<p>Like any other website, Heinrig Filmtechnik uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

<p>For more general information on cookies, please read <a href="https://www.generateprivacypolicy.com/#cookies">"Cookies" article from the Privacy Policy Generator</a>.</p>



<h2 className="font-semibold text-xl">Privacy Policies</h2>

<p>You may consult this list to find the Privacy Policy for each of the advertising partners of Heinrig Filmtechnik.</p>

<p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Heinrig Filmtechnik, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

<p>Note that Heinrig Filmtechnik has no access to or control over these cookies that are used by third-party advertisers.</p>

<h2 className="font-semibold text-xl">Third Party Privacy Policies</h2>

<p>Heinrig Filmtechnik's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

<p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

<h2 className="font-semibold text-xl">Children's Information</h2>

<p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

<p>Heinrig Filmtechnik does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

<h2 className="font-semibold text-xl">Online Privacy Policy Only</h2>

<p>Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Heinrig Filmtechnik. This policy is not applicable to any information collected offline or via channels other than this website.</p>

<h2 className="font-semibold text-xl">Consent</h2>

<p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>  
        </div>
      </Layout>
  )
}

export default philosophyPage